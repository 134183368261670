import Dexie from 'dexie';

export const userDB = new Dexie(process.env.VUE_APP_NAME + '-' + (localStorage.getItem(process.env.VUE_APP_NAME) ? JSON.parse(localStorage.getItem(process.env.VUE_APP_NAME)).user.username : 'anonymous'));

userDB.version(3).stores({
  coldCalls: 'id, name, nameSearch, phoneNumber, stateLabel, note, lastCallTime',
  potentials: 'id, name, nameSearch, phoneNumber, stateLabel, note, subStatus, lastCallTime',
  hotHits: 'id, name, nameSearch, phoneNumber, stateLabel, note, subStatus, lastCallTime',
  denieds: 'id, name, nameSearch, phoneNumber, stateLabel, note, lastCallTime',
  closedCases: 'id, name, nameSearch, phoneNumber, stateLabel, note, lastCallTime',
  callResults: 'id, syncId, name, phoneNumber, stateLabel, createdAt, status, type',
  customerUpdates: 'id, syncId',
  customerPotentials: 'id, syncId',
  customerInserts: 'syncId',
  customerNotes: 'syncId',
  potentialSubStatus: 'id, syncId',
  hotHitSubStatus: 'id, syncId',
  tasksRemoved: 'id, syncId',
})

userDB.open()