import Vue from 'vue';
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import * as constants from '@/constants'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import generalMixin from '@/mixins/generalMixin'
import i18n from '@/libs/i18n'
import AuthRepository from "@/modules/auth/repository/authRepository";
import { initialAbility } from '@/libs/acl/config'
import getBrowserFingerprint from 'get-browser-fingerprint';
import md5 from 'md5';
import { mainDB } from "@/database/mainDB";
import { userDB } from "@/database/userDB";

export const http = {
  install(Vue, options)
  {
    this.setDefaults();
    this.addInterceptors();
    this.sessionTime();
  },
  setDefaults() {
    const fingerprint = md5(getBrowserFingerprint() + localStorage.getItem('vuexy-theme'));
    axios.defaults.baseURL = constants.API_BASE_URL;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Content-Language'] = store.state.config.locale;
    axios.defaults.headers.common['Browser-Id'] = fingerprint;
  },
  addInterceptors() {
    const vm = this;
    store.watch((state) => {
      return state.auth.accessToken
    }, (accessToken) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    }, {
      deep: true
    });
    axios.interceptors.request.use(function (config) {
      let sessions = store.state.sessions
      if(sessions.stageLoading == false && sessions.enableLoading == true) {
        sessions.stageLoading = true
        store.dispatch('sessions/update', sessions)
      }
      return config;
    }, function (error) {
      Vue.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: i18n.t('Error Message'),
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: i18n.t('Connection Errors'),
        },
      })
      return Promise.reject(error);
    });
    axios.interceptors.response.use(function (response) {
      let sessions = store.state.sessions
      if(sessions.stillLoading == false) {
        sessions.stageLoading = false
        store.dispatch('sessions/update', sessions)
      }
      if(typeof response.data.data.message != 'undefined' && response.data.data.message != '') {
        Vue.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: i18n.t('Success'),
            icon: "CoffeeIcon",
            variant: "success",
            text: generalMixin.methods.convertMessageFromAPI(response.data.data.message)
          },
        });
      }
      return response;
    }, function (error) {
      if (typeof error.response != 'undefined') {
        if (error.response.status === 401) {
          // if(error.response.data.message != '') {
          //   Vue.$toast({
          //     component: ToastificationContent,
          //     position: 'top-right',
          //     props: {
          //       title: i18n.t('Error Message'),
          //       icon: 'AlertCircleIcon',
          //       variant: 'danger',
          //       html: generalMixin.methods.convertMessageFromAPI(error.response.data.message),
          //     },
          //   })
          // }
          const auth = store.state.auth
          auth.isLoggedIn = false
          auth.accessToken = null
          store.dispatch('auth/update', auth)

          const user = store.state.user
          user.fullName = ""
          user.username = ""
          user.role = ""
          user.ability = null
          store.dispatch('user/update', user)

          router.push({ name: 'not-authorized' });
        }
        if (error.response.status === 404 || error.response.status === 500 || error.response.status === 400 || error.response.status === 405 || error.response.status === 422) {
          if(error.response.data.message != '') {
            Vue.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('Error Message'),
                icon: 'AlertCircleIcon',
                variant: 'danger',
                html: generalMixin.methods.convertMessageFromAPI(error.response.data.message),
              },
            })
          }
        }
        if (error.response.status === 403) {
          if(error.response.data.message != '') {
            Vue.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('Error Message'),
                icon: 'AlertCircleIcon',
                variant: 'danger',
                html: generalMixin.methods.convertMessageFromAPI(error.response.data.message),
              },
            })
          }
          router.push({ name: 'sendemail' });
        }
      }
      let sessions = store.state.sessions
      sessions.stageLoading = false
      store.dispatch('sessions/update', sessions)
      return Promise.reject(error);
    });

  },
  storeToken(response) {
    // Auth
    const auth = store.state.auth
    auth.isLoggedIn = true
    auth.accessToken = response.data.data.user.userToken
    auth.sessionTime = new Date().getTime();
    store.dispatch('auth/update', auth)
    this.sessionTime();
    // User
    const user = store.state.user
    user.fullName = response.data.data.user.fullName
    user.username = response.data.data.user.username
    user.role = response.data.data.user.role
    user.ability = response.data.data.user.ability
    store.dispatch('user/update', user)

    store.dispatch("task/clear");
  },
  logout() {
    var localData = JSON.parse(localStorage.getItem(constants.STORAGE_KEY));
    if(localData.auth.isLoggedIn) {
      AuthRepository.logout().then((response) => {
        const auth = store.state.auth
        auth.isLoggedIn = false
        auth.accessToken = null
        store.dispatch('auth/update', auth)

        const user = store.state.user
        user.fullName = ""
        user.username = ""
        user.role = ""
        user.ability = null
        store.dispatch('user/update', user)

        location.reload()
      });
    }
  },
  clearSettings() {
    mainDB.close();
    userDB.close();
    window.indexedDB.databases().then((r) => {
        for (var i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name);
    }).then(() => {});
    this.logout();
  },
  sessionTime() {
    const auth = store.state.auth
    const user = store.state.user
    if(auth.isLoggedIn) {
      var nowTime = new Date().getTime();
      var timeLength = nowTime - auth.sessionTime;
      if(timeLength > process.env.VUE_APP_EXPIRE_TIME*60*1000) {
        auth.isLoggedIn = false
        auth.accessToken = null
        store.dispatch('auth/update', auth)

        user.fullName = ""
        user.username = ""
        user.role = ""
        user.ability = null
        store.dispatch('user/update', user)

        router.push({ name: 'auth-login' })
      } else {
        auth.sessionTime = new Date().getTime();
        store.dispatch('auth/update', auth);
      }
    }
    const sessionTimeInterval = setInterval(() => {
      if(auth.isLoggedIn) {
        var nowTime = new Date().getTime();
        var timeLength = nowTime - auth.sessionTime;
        if(timeLength > process.env.VUE_APP_EXPIRE_TIME*60*1000) {
          auth.isLoggedIn = false
          auth.accessToken = null
          store.dispatch('auth/update', auth)

          user.fullName = ""
          user.username = ""
          user.role = ""
          user.ability = null
          store.dispatch('user/update', user)

          clearInterval(sessionTimeInterval);
        } else {
          auth.sessionTime = new Date().getTime();
          store.dispatch('auth/update', auth);
        }
      } else {
        clearInterval(sessionTimeInterval);
      }
    }, (process.env.VUE_APP_EXPIRE_TIME - 2)*60*1000)
  },
}

export default { router }
