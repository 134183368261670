const defaults = {
  isLoggedIn: false,
  accessToken: null,
  sessionTime: null,
}

const auth = {
  namespaced: true,
  state: { ...defaults },
  mutations: {
    update(data) {
      auth.state = { ...defaults, ...data }
    },
    clear(state) {
      auth.state = { ...state, ...defaults }
    },
  },

  actions: {
    clear({ commit }) {
      commit('clear')
    },
    update({ commit }, data) {
      commit('update', data)
    },
  },
}

export default auth
