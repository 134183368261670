export default {
    methods: {
        showLoading () {
            let sessions = this.$store.state.sessions
            sessions.stageLoading = true
            this.$store.dispatch('sessions/update', sessions)
        },
        hideLoading () {
            let sessions = this.$store.state.sessions
            sessions.stageLoading = false
            this.$store.dispatch('sessions/update', sessions)
        },
        enableStillLoading () {
            let sessions = this.$store.state.sessions
            sessions.stillLoading = true
            this.$store.dispatch('sessions/update', sessions)
        },
        disableStillLoading () {
            let sessions = this.$store.state.sessions
            sessions.stillLoading = false
            this.$store.dispatch('sessions/update', sessions)
        },
        enableCenterLoading () {
            let sessions = this.$store.state.sessions
            sessions.centerLoading = true
            this.$store.dispatch('sessions/update', sessions)
        },
        disableCenterLoading () {
            let sessions = this.$store.state.sessions
            sessions.centerLoading = false
            this.$store.dispatch('sessions/update', sessions)
        },
        enableLoading () {
            let sessions = this.$store.state.sessions
            sessions.enableLoading = true
            this.$store.dispatch('sessions/update', sessions)
        },
        disableLoading () {
            let sessions = this.$store.state.sessions
            sessions.enableLoading = false
            this.$store.dispatch('sessions/update', sessions)
        },
        showTopLoading () {
            let sessions = this.$store.state.sessions
            sessions.topLoading = true
            this.$store.dispatch('sessions/update', sessions)
        },
        hideTopLoading () {
            let sessions = this.$store.state.sessions
            sessions.topLoading = false
            this.$store.dispatch('sessions/update', sessions)
        },
        autoSaveLoading() {
            this.showTopLoading();
            setTimeout(() => {
                this.hideTopLoading();
            }, 1000)
        },
        autoShowDetailLoading() {
            this.showTopLoading();
            setTimeout(() => {
                this.hideTopLoading();
            }, 200)
        },
    },
};
