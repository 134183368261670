export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('../feature/login.vue'),
    meta: {
      pageTitle: 'Login',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      isPublic: true
    },
  },
  {
    path: '/activate/:activationCode',
    name: 'auth-activate',
    component: () => import('../feature/activate.vue'),
    meta: {
      pageTitle: 'Activate',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
      isPublic: true
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('../feature/forgotPassword.vue'),
    meta: {
      pageTitle: 'Forgot password',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
      isPublic: true
    },
  },
  {
    path: '/reset-password/:resetPasswordCode',
    name: 'auth-reset-password',
    component: () => import('../feature/resetPassword.vue'),
    meta: {
      pageTitle: 'Reset Password',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
      isPublic: true
    },
  },
]
