import * as constants from '@/constants'
import auth from '@/modules/auth/store/auth'
import user from '@/modules/user/store/user'
import config from '../config'
import task from '@/modules/task/store/task'
import taskSync from '@/modules/task/store/taskSync'
import axios from 'axios'

// Sync with local storage.
if (localStorage.getItem(constants.STORAGE_KEY)) {
  const syncedState = JSON.parse(localStorage.getItem(constants.STORAGE_KEY))
  auth.state = Object.assign(auth.state, syncedState.auth)
  user.state = Object.assign(user.state, syncedState.user)
  config.state = Object.assign(config.state, syncedState.config)
  task.state = Object.assign(task.state, syncedState.task)
  taskSync.state = Object.assign(taskSync.state, syncedState.taskSync)
  axios.defaults.headers.common.Authorization = `Bearer ${auth.state.accessToken}`
}

export default store => {
  store.subscribe((mutation, state) => {
    var taskState = {}
    Object.keys(task.defaults).forEach(function(key) {
      taskState[key] = state.task[key]
    });
    const syncedData = {
      auth: state.auth,
      user: state.user,
      config: state.config,
      task: taskState,
      taskSync: state.taskSync,
    }
    localStorage.setItem(constants.STORAGE_KEY, JSON.stringify(syncedData))
    if (mutation.type === 'common/clear') {
      localStorage.removeItem(constants.STORAGE_KEY)
    }
  })
};
