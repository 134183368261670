const defaults = {
  staffId: {
    value: null
  },
  sessionTime: null
}

const taskSync = {
  namespaced: true,
  defaults: defaults,
  state: { ...defaults },
  mutations: {
    update(data) {
      taskSync.state = { ...defaults, ...data }
    },
    clear(state) {
      taskSync.state = { ...state, defaults }
    },
  },

  actions: {
    clear({ commit }) {
      commit('clear')
    },
    update({ commit }, data) {
      commit('update', data)
    },
  },
}

export default taskSync
