import i18n from '@/libs/i18n'
import { mainDB } from "@/database/mainDB"

export default {
    methods: {
        formatDateMMDDYYYYHH(date) {
            var month = date.getMonth() + 1;
            month = month < 10 ? '0'+month : month;
            var day = date.getDate();
            day = day < 10 ? '0'+day : day;
            var year = date.getFullYear();
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            hours = hours < 10 ? '0'+hours : hours;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return month + "/" + day + "/" + year + " " + strTime;
        },
        convertMessageFromAPI(message) {
            let messageString = '';
            if(typeof message === 'object') {
                Object.entries(message).forEach(([key, value]) => {
                    messageString += value.join('<br>');
                    messageString += '<br>';
                });
                messageString += '<br>';
            }
            if(typeof message === 'string') {
                messageString = message;
            }
            return messageString;
        },
        async showDeleteConfirmDialog(customMessage) {
            let confirmValue = null;
            let message = customMessage ? customMessage : i18n.t('message.Are you sure you want to delete this item?')
            await this.$bvModal.msgBoxConfirm(message, {
                title: i18n.t('Warning'),
                size: 'sm',
                okVariant: 'primary',
                okTitle: i18n.t('Yes'),
                cancelTitle: i18n.t('No'),
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
            })
            .then(value => {
                confirmValue = value;
            })
            return confirmValue;
        },
        async showConfirmDialog() {
            let confirmValue = null;
            await this.$bvModal.msgBoxConfirm(i18n.t('message.Do you want to continue?'), {
                title: i18n.t('Warning'),
                size: 'sm',
                okVariant: 'primary',
                okTitle: i18n.t('Yes'),
                cancelTitle: i18n.t('No'),
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
            })
            .then(value => {
                confirmValue = value;
            })
            return confirmValue;
        },
        removeItemFromArrayOfObject(arrayOfObject, key, item) {
            var newArray = JSON.parse(JSON.stringify(arrayOfObject));
            if(newArray.findIndex(a => a[key] === item[key]) != -1) {
                newArray.splice(newArray.findIndex(a => a[key] === item[key]) , 1)
            }
            return newArray;
        },
        formatPhoneNumber(phoneNumberString) {
            //+1 (###) ###-####
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4]
            }
            return phoneNumberString
        },
        formatPhoneNumberShort(phoneNumberString) {
            return phoneNumberString.replace(/\D/g, '');
        },
        async getStateByPhone(phoneNumber) {
            let stateId = null;
            await mainDB.areaCodes.get({areaCode: phoneNumber.substring(4,7)})
            .then(response => {
                if(typeof response != 'undefined') stateId = response.stateId
            })
            return stateId
        },
        async getStateLabelByPhone(phoneNumber) {
            let stateLabel = null;
            await mainDB.areaCodes.get({areaCode: phoneNumber.substring(4,7)})
            .then(response => {
                if(typeof response != 'undefined') stateLabel = response.stateLabel
            })
            return stateLabel
        },
        async getStateLabel(stateId) {
            let state = await mainDB.states.get(stateId)
            if(state) return state.stateLabel
            return '';
        },
        getCurrentTimeByTimeZone(timeZone) {
            var setting01 = {day: 'numeric', month: 'short', year: 'numeric', hour12: false, minute: 'numeric', hour: 'numeric'};
            var setting02 = {day: 'numeric', month: 'short', year: 'numeric', hour12: true, minute: 'numeric', hour: 'numeric'};
            var option01 = { timeZone: timeZone, ...setting01 };
            var option02 = { timeZone: timeZone, ...setting02 };
            var arr01 = new Date().toLocaleDateString('en-US', option01).split(" ");
            var arr02 = new Date().toLocaleDateString('en-US', option02).split(" ");
            return arr01[3] + ' ' + arr02[4]
        },
        formatTimeZone(time) {
            if(time == 0) return '';
            var timeZone = process.env.VUE_APP_DEFAULT_TIMEZONE != '' ? process.env.VUE_APP_DEFAULT_TIMEZONE : 'America/New_York';
            var setting01 = {day: '2-digit', month: '2-digit', year: 'numeric', hour12: false, minute: '2-digit', hour: '2-digit'};
            var setting02 = {day: '2-digit', month: '2-digit', year: 'numeric', hour12: true, minute: '2-digit', hour: '2-digit'};
            var option01 = { timeZone: timeZone, ...setting01 };
            var option02 = { timeZone: timeZone, ...setting02 };
            var arr01 = new Date(time).toLocaleDateString('en-US', option01).split(" ");
            var arr02 = new Date(time).toLocaleDateString('en-US', option02).split(" ");
            var timeFormated = arr01[1] + ' ' + arr02[2] + '<br>' + arr01[0].replace(/,/g, '');
            return timeFormated;
        },
        formatTimeZoneOneLine(time) {
            if(time == 0) return '';
            var timeZone = process.env.VUE_APP_DEFAULT_TIMEZONE != '' ? process.env.VUE_APP_DEFAULT_TIMEZONE : 'America/New_York';
            var setting01 = {day: '2-digit', month: '2-digit', year: 'numeric', hour12: false, minute: '2-digit', hour: '2-digit'};
            var setting02 = {day: '2-digit', month: '2-digit', year: 'numeric', hour12: true, minute: '2-digit', hour: '2-digit'};
            var option01 = { timeZone: timeZone, ...setting01 };
            var option02 = { timeZone: timeZone, ...setting02 };
            var arr01 = new Date(time).toLocaleDateString('en-US', option01).split(" ");
            var arr02 = new Date(time).toLocaleDateString('en-US', option02).split(" ");
            var timeFormated = arr01[0].replace(/,/g, '') + ' '  + arr01[1] + ' ' + arr02[2];
            return timeFormated;
        },
        getCallStatusVariant(status) {
            var arrStatusVariant = {
                no_answer: 'primary',
                answer: 'success',
                hang_up: 'info',
                wrong_number: 'danger',
                busy: 'warning',
                no_need: 'dark'
            };
            return arrStatusVariant[status];
        },
        getCurrentTimestamp() {
            return new Date().getTime();
        },
        getCurrentTimestampSeconds() {
            return Math.floor(Date.now() / 1000);
        },
        getPageTypeVariant(tab) {
            switch (tab) {
                case 'cold_call': return 'primary';
                case 'potential': return 'info';
                case 'hothit': return 'success';
                default: return 'primary';
            }
        },
        truncateString(str, num = 200) {
            if (str.length <= num) {
              return str
            }
            return str.slice(0, num) + '...'
        },
        generateSyncId() {
            return this.getCurrentTimestamp() + ':' + Math.random().toString(36).substring(2).toUpperCase()
        },
        removeVietnameseTones(str) {
            str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a");
            str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e");
            str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i");
            str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o");
            str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u");
            str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y");
            str = str.replace(/đ/g,"d");
            str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
            str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
            str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
            str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
            str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
            str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
            str = str.replace(/Đ/g, "D");
            // Some system encode vietnamese combining accent as individual utf-8 characters
            // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
            str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
            str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
            // Remove extra spaces
            // Bỏ các khoảng trắng liền nhau
            str = str.replace(/ + /g," ");
            str = str.trim();
            // Remove punctuations
            // Bỏ dấu câu, kí tự đặc biệt
            str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
            str = str.replace(/[^a-zA-Z0-9]/g, ' ');
            str = str.trim();
            return str;
        }
    },
};
