const defaults = {
  locale: 'vi'
}

const config = {
  namespaced: true,
  state: { ...defaults },
  mutations: {
    update(data) {
      config.state = { ...defaults, ...data }
    },
    clear(state) {
      config.state = { ...state, defaults }
    },
  },

  actions: {
    clear({ commit }) {
      commit('clear')
    },
    update({ commit }, data) {
      commit('update', data)
    },
  },
}

export default config
