const defaults = {
  stageLoading: false,
  stillLoading: false,
  centerLoading: true,
  enableLoading: true,
  topLoading: false,
}

const sessions = {
  namespaced: true,
  state: { ...defaults },
  mutations: {
    update(data) {
      sessions.state = { ...defaults, ...data }
    },
    clear(state) {
      sessions.state = { ...state, defaults }
    },
  },

  actions: {
    clear({ commit }) {
      commit('clear')
    },
    update({ commit }, data) {
      commit('update', data)
    },
  },
}

export default sessions
