export default [
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('../feature/tasks/tasks.vue'),
    meta: {
      pageTitle: 'Tasks',
      resource: 'task.tasks',
      action: 'read'
    },
  }
]