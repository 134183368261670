import Vue from 'vue'
import Vuex from 'vuex'
// import auth from '@/modules/auth/store/auth'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import localStorage from './local-storage'
import config from './config'
import task from '@/modules/task/store/task'
import taskSync from '@/modules/task/store/taskSync'
import sessions from './sessions'
import auth from '@/modules/auth/store/auth'
import user from '@/modules/user/store/user'
//import sendemail from '@/modules/sendemail/store/sendemail'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    config,
    task,
    taskSync,
    sessions,
    auth,
    user,
    //sendemail
  },
  plugins: [localStorage],
  strict: process.env.DEV,
})
