import Dexie from 'dexie';

export const mainDB = new Dexie(process.env.VUE_APP_NAME);

mainDB.version(3).stores({
  states: 'id, stateName, stateCode',
  areaCodes: 'areaCode, stateId',
  staffs: 'id, shortName, fullName',
  potentialFollowingCustomers: 'phoneNumber, updatedAt',
  hotHitFollowingCustomers: 'phoneNumber, updatedAt',
  closedCaseFollowingCustomers:  'phoneNumber, updatedAt'
})

mainDB.open()