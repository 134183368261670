export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../feature/profile/view.vue'),
    meta: {
      pageTitle: 'Profile',
      resource: 'user.profile',//Check Permissions
      action: 'read',//Check Permissions
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../feature/users/list/list.vue'),
    meta: {
      pageTitle: 'Users',
      resource: 'user.users',
      action: 'read',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/view/:id',
    name: 'users-view',
    component: () => import('../feature/users/view/view.vue'),
    meta: {
      pageTitle: 'Details',
      resource: 'user.users',
      action: 'read',
      navActiveLink: 'users', //navActiveLink: name of the route need to active
      breadcrumb: [
        {
          text: 'Users',
          to: '/users'
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('../feature/users/edit/edit.vue'),
    meta: {
      pageTitle: 'Edit',
      resource: 'user.users',
      action: 'read',
      navActiveLink: 'users',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/roles',
    name: 'users-roles',
    component: () => import('../feature/roles/list/list.vue'),
    meta: {
      pageTitle: 'Roles',
      resource: 'user.users',
      action: 'read',
      breadcrumb: [
        {
          text: 'Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/roles/edit/:id',
    name: 'users-roles-edit',
    component: () => import('../feature/roles/edit/edit.vue'),
    meta: {
      pageTitle: 'Edit',
      resource: 'user.users',
      action: 'read',
      navActiveLink: 'users-roles',
      breadcrumb: [
        {
          text: 'Roles',
          to: '/users/roles'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]