const defaults = {
  tabChoosed: 'cold_call',
  indexChoosed: null,
  isShowDetails: false,
  isCalling: false,
  taskDetailsData: {},
  callResult: {
    id: null,
    createdAt: null,
    status: null,
    note: null
  },
  staffChoosed: null
  //customerSyncPage: 1
}

const task = {
  namespaced: true,
  defaults: defaults,
  state: { ...defaults },
  mutations: {
    update(data) {
      task.state = { ...defaults, ...data }
    },
    clear(state) {
      task.state = { ...state, defaults }
    },
  },

  actions: {
    clear({ commit }) {
      commit('clear')
    },
    update({ commit }, data) {
      commit('update', data)
    },
  },
}

export default task
