import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import authRoutes from '@/modules/auth/route/authRoutes'
import pageRoutes from '@/modules/page/route/pageRoutes'
import userRoutes from '@/modules/user/route/userRoutes'
import customerRoutes from '@/modules/customer/route/customerRoutes'
import taskRoutes from '@/modules/task/route/taskRoutes'
import sendemailRoutes from '@/modules/sendemail/route/sendemailRoutes'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import i18n from '@/libs/i18n'
import store from '@/store'
import { localize } from 'vee-validate'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },
    ...authRoutes,
    ...pageRoutes,
    ...userRoutes,
    ...customerRoutes,
    ...taskRoutes,
    ...sendemailRoutes,
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  let config = store.state.config
  i18n.locale = config.locale;
  document.title = i18n.t(to.meta.pageTitle)
  localize(i18n.locale);
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  if(to.name == 'tasks') {
    let sessions = store.state.sessions
    sessions.enableLoading = false
    store.dispatch('sessions/update', sessions)
  } else {
    let sessions = store.state.sessions
    sessions.enableLoading = true
    store.dispatch('sessions/update', sessions)
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
