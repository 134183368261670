import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { localize } from 'vee-validate'
import vi from 'vee-validate/dist/locale/vi.json'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import { http } from './http'
import actionLoading from '@/mixins/actionLoading'
import generalMixin from '@/mixins/generalMixin'
import Vue2Filters from 'vue2-filters'
import vueTopprogress from 'vue-top-progress'
import VueMask from 'v-mask'
import { ref } from '@vue/composition-api'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(http)
Vue.use(Vue2Filters)
Vue.use(vueTopprogress)
Vue.use(VueMask);
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.mixin(actionLoading)
Vue.mixin(generalMixin)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

require('@core/scss/vue/libs/vue-flatpicker.scss')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Install and Activate the Arabic locale.
localize('vi', vi)

Vue.config.productionTip = false
Vue.prototype.$isSearch = ref(false)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
