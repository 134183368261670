export default [
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../feature/list/list.vue'),
    meta: {
      pageTitle: 'Customer',
      resource: 'customer.customers',
      action: 'read',
      breadcrumb: [
        {
          text: 'Customer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/import',
    name: 'customers-import',
    component: () => import('../feature/import/import.vue'),
    meta: {
      pageTitle: 'Import',
      resource: 'customer.customers',
      action: 'read',
      breadcrumb: [
        {
          text: 'Import',
          active: true,
        },
      ],
    },
  },
]