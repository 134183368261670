const defaults = {
  fullName: '',
  role: '',
  ability: null,
}

const user = {
  namespaced: true,
  state: { ...defaults },
  mutations: {
    update(data) {
      user.state = { ...defaults, ...data }
    },
    clear(state) {
      user.state = { ...state, defaults }
    },
  },

  actions: {
    clear({ commit }) {
      commit('clear')
    },
    update({ commit }, data) {
      commit('update', data)
    },
  },
}

export default user
