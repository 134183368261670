import axios from 'axios'

export default class AuthRepository {
  static login(data) {
    return axios.post('/api/login', data)
  }
  static logout() {
    return axios.get('/api/logout')
  }
  static checkAuth() {
    return axios.get('/api/check-auth')
  }
  static checkActivationCode(data) {
    return axios.post('/api/check-activation-code', data)
  }
  static activateAccount(data) {
    return axios.put('/api/activate-account', data)
  }
  static sendResetPasswordLink(data) {
    return axios.post('/api/send-reset-password-link', data)
  }
  static checkResetPasswordCode(data) {
    return axios.post('/api/check-reset-password-code', data)
  }
  static resetPassword(data) {
    return axios.put('/api/reset-password', data)
  }
}
