<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
    :key="$i18n.locale"
  >
    <b-overlay
      :show="this.$store.state.sessions.stageLoading && this.$store.state.sessions.centerLoading"
      variant="transparent"
      opacity="0.5"
      blur="2px"
      spinner-variant="primary"
      rounded="sm"
      class="full-loading"
    >
      <component :is="layout">
        <router-view />
      </component>
    </b-overlay>
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { BOverlay } from 'bootstrap-vue'
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'
import store from '@/store'
import router from '@/router'
import AuthRepository from "@/modules/auth/repository/authRepository";

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BOverlay,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    stageLoading() {
      return this.$store.state.sessions.stageLoading
    },
    topLoading() {
      return this.$store.state.sessions.topLoading
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup(props, context) {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    const vm = context.root

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  created() {
    if(process.env.VUE_APP_UNDER_MAINTENANCE == 'true') {
      router.push({ name: 'under-maintenance'})
    }
    const auth = store.state.auth
    window.addEventListener('beforeunload', () => {
      auth.sessionTime = new Date().getTime();
      store.dispatch('auth/update', auth)
    })
  },
  watch: {
    stageLoading(stageLoading) {
      if(stageLoading)
        this.$refs.topProgress.start()
      else
        this.$refs.topProgress.done()
    },
    topLoading(topLoading) {
      if(topLoading)
        this.$refs.topProgress.start()
      else
        this.$refs.topProgress.done()
    },
  },
}
</script>
<style lang="scss">
    @import '../node_modules/element-ui/lib/theme-chalk/index.css';
</style>