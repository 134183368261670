export default [
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('../feature/NotAuthorized.vue'),
    meta: {
      pageTitle: 'Not Authorized',
      layout: 'full',
      isPublic: true
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../feature/Error.vue'),
    meta: {
      pageTitle: 'Error',
      layout: 'full',
      isPublic: true
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('../feature/Error404.vue'),
    meta: {
      pageTitle: 'Error',
      layout: 'full',
      isPublic: true
    },
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: () => import('../feature/ComingSoon.vue'),
    meta: {
      pageTitle: 'Coming Soon',
      layout: 'full',
      isPublic: true,
    },
  },
  {
    path: '/under-maintenance',
    name: 'under-maintenance',
    component: () => import('../feature/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Under Maintenance',
      layout: 'full',
      isPublic: true,
    },
  },
]
