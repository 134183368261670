export default [
  {
    path: '/send-email',
    name: 'sendemail',
    component: () => import('../feature/sendemail.vue'),
    meta: {
      pageTitle: 'Modules.User.Label.Send Email',
      resource: 'user.users',
      action: 'read',
      breadcrumb: [
        {
          text: 'Modules.User.Label.Send Email',
          active: true,
        },
      ],
    },
  },
]
